import React, { useState } from "react"
import PageLayout from "../../components/PageLayout"
import PageHeader from "../../components/PageHeader"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { PropTypes } from "prop-types"
import { useSpring, animated } from "@react-spring/web"

const MusicItem = ({ work, handleHover, handleLeave, dim }) => {
  const [hovered, setHovered] = useState(false)
  const itemProps = useSpring({
    scale: hovered ? "1.05" : "1",
    opacity: dim ? 0.5 : 1,
  })
  return (
    <div
      className="col-6 col-md-4"
      onMouseOver={() => {
        handleHover()
        !hovered && setHovered(true)
      }}
      onMouseLeave={() => {
        handleLeave()
        setHovered(false)
      }}
    >
      <Link to={work.slug}>
        <animated.div style={itemProps}>
          <GatsbyImage
            image={work.coverImage.gatsbyImageData}
            alt={work.coverAlt}
          />
        </animated.div>
      </Link>
    </div>
  )
}

MusicItem.propTypes = {
  work: PropTypes.object.isRequired,
  handleHover: PropTypes.func.isRequired,
  handleLeave: PropTypes.func.isRequired,
  dim: PropTypes.bool.isRequired,
}

const Music = ({ data }) => {
  const works = data.works.nodes
  console.log(works[0])
  const metaImage = getSrc(works[0].coverImage.ogImage)

  const [hoveredIndex, setHoveredIndex] = useState(-1)

  return (
    <PageLayout
      title={"Music"}
      contentPath="music"
      metaImage={metaImage}
      metaDescription="Selections of music by composer Tim Sandberg"
    >
      <PageHeader title={"Music"} />
      <div className="row g-3">
        {works.map((work, i) => (
          <MusicItem
            work={work}
            dim={hoveredIndex !== -1 && i !== hoveredIndex}
            handleLeave={() => i == hoveredIndex && setHoveredIndex(-1)}
            handleHover={() => setHoveredIndex(i)}
            key={`music-item-${work.id}`}
          />
        ))}
      </div>
    </PageLayout>
  )
}

Music.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    works: allContentfulMusic(sort: { fields: releaseDate, order: DESC }) {
      nodes {
        id
        slug
        title
        coverAlt
        releaseDate(formatString: "LL")
        coverImage {
          gatsbyImageData(layout: FULL_WIDTH)
          ogImage: gatsbyImageData(width: 1200, height: 627, cropFocus: CENTER)
        }
      }
    }
  }
`

export default Music
